import React, { useState, useEffect } from "react";

import {
	shorthands,
	makeStyles,
	DrawerBody,
	DrawerHeader,
	DrawerHeaderTitle,
	Drawer,
	Dialog,
	DialogSurface,
	DialogBody,
	DialogContent,
	DialogActions,
	Field,
	Button,
	Spinner,
	Menu,
	MenuTrigger,
	MenuList,
	MenuItem,
	MenuPopover,
	Subtitle1,
	DialogTrigger,
	DialogTitle,
	Textarea,
	Label,
	Combobox,
	Option,
	Input,
	Text,
	Title1,
	Divider,
	Tag,
} from "@fluentui/react-components";
import { Stack } from "@fluentui/react/lib/Stack";

import {
	bundleIcon,
	Dismiss24Regular,
	EditFilled,
	EditRegular,
	ChevronLeftFilled,
	DeleteRegular,
	DocumentDataRegular,
	ArrowCounterclockwise16Regular,
	Phone16Regular,
	Mail16Regular,
	ArrowCounterclockwiseFilled,
} from "@fluentui/react-icons";

import {
	Candidate,
	Experience,
	Education,
	CandidateAnalysis,
} from "../../models/Candidate";

import { sourceOptions, statusOptions } from "../../constants/dropdownOptions";

import axios from "axios";

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import CandidateLoadingSkeleton from "./CandidateLoadingSkeleton";
import { CandidatesComment } from "./CandidatesComment";
import CvModal from "../../components/cvModal";
import "../../styles/DetailPage.css";
import { formattedDate } from "../../utils/dateUtils";
import { useMsal } from "@azure/msal-react";

import { getRolePermissions } from "../../permissions/rolePermissions";

const useStyles = makeStyles({
	drawerBody: {
		...shorthands.overflow("hidden"),
		"& > *:last-child": {
			paddingBottom: 0,
		},
	},
	loadingOverlay: {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		backgroundColor: "rgba(255, 255, 255, 0.5)",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		zIndex: 1000,
	},
});

export default function CandidatesDetailDrawer(props: any) {
	const styles = useStyles();
	const queryClient = useQueryClient();
	const {
		isDetailDrawerOpen,
		setIsDetailDrawerOpen,
		selectedIntID,
		setSelectedIntID,
		setIsSearchPanelVisible,
	} = props;

	const Edit = bundleIcon(EditFilled, EditRegular);

	const [isFormOpen, setIsFormOpen] = useState(false);
	const [showSpinner, setShowSpinner] = useState(false);
	const [editSuccess, setEditSuccess] = useState(false);
	const [editErrorMsg, setEditErrorMsg] = useState<string | undefined>("");

	const [deleteStatus, setDeleteStatus] = useState<string | undefined>(
		undefined
	);

	const [isDeletingCandidate, setIsDeletingCandidate] = useState(false);

	const emptyDetails = {
		id: undefined,
		name: "",
		skills: "",
		isSkillExpanded: false,
		source: "",
		status: "",
		workExperience: [],
		education: [],
		lastModified: "",
		currentProfile: "",
		expectedMinSalary: 0,
		expectedMaxSalary: 0,
	};
	const [candidateDetails, setCandidateDetails] =
		useState<Candidate>(emptyDetails);

	const [candidateAnalysis, setCandidateAnalysis] =
		useState<CandidateAnalysis | null>(null);

	const [editDetails, setEditDetails] = useState<Candidate>(emptyDetails);

	const {
		isLoading,
		error,
		data: retrieveData,
		refetch,
	} = useQuery(
		["candidate-detail", selectedIntID],
		() =>
			selectedIntID
				? axios
						.get(`/candidates/${selectedIntID}`)
						.then((response) => response.data)
				: null,
		{
			refetchOnWindowFocus: false,
			onSuccess: (data) => {
				if (data) {
					setCandidateDetails(data);
				}
			},
			enabled: !!selectedIntID, // only run query if selectedIntID is not null
		}
	);

	const { instance } = useMsal();
	let activeAccount;

	let userRoles: string[] = [];
	if (instance) {
		activeAccount = instance.getActiveAccount();
		if (activeAccount?.idTokenClaims?.roles) {
			userRoles = activeAccount.idTokenClaims.roles as string[];
		}
	}

	const { showUploadButton, showEditDetailsButton, showDeleteCandidateButton } =
		getRolePermissions(activeAccount);

	useEffect(() => {
		const getCandidateAnalysis = async () => {
			if (selectedIntID) {
				try {
					const response = await axios.get(
						`/candidate/${selectedIntID}/analysis`
					);
					setCandidateAnalysis(response.data);
				} catch (error) {
					console.error("Error fetching candidate analysis:", error);
				}
			}
		};

		getCandidateAnalysis();
	}, [selectedIntID]);

	const editOpening = useMutation({
		mutationFn: (formData: Candidate) => {
			setEditSuccess(false);
			setShowSpinner(true);
			setEditErrorMsg("");
			return axios
				.put(`/candidates/${selectedIntID}`, formData, {
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
				})
				.then((response) => response.data);
		},
		onSuccess: (data: Candidate) => {
			console.log(data);
			setIsFormOpen(false);
			setEditSuccess(true);
			setShowSpinner(false);
		},
		onError: (error: any) => {
			console.error(error);
			setShowSpinner(false);
			setEditErrorMsg(
				error.response?.data?.msg || "An error occurred. Please try again."
			);
		},
	});

	useEffect(() => {
		refetch();
	}, [editSuccess, candidateDetails]);

	useEffect(() => {
		setEditDetails(candidateDetails);
	}, [isFormOpen]);

	const handleSubmit = (e: any, formData: Candidate) => {
		e.preventDefault();
		editOpening.mutate(formData);
		console.log("submit form is clicked", formData);
	};

	const handleInputChange = (fieldName: string, input: any) => {
		let value: string | number | null;
		if (typeof input === "string" && input.trim() === "") {
			value = null;
		} else if (!isNaN(input)) {
			value = parseFloat(input);
		} else {
			value = input;
		}
		setEditDetails((prevDetails) => ({
			...prevDetails,
			[fieldName]: value,
		}));
	};

	const deleteCandidate = useMutation({
		mutationFn: () => {
			setIsDeletingCandidate(true);
			return axios
				.delete(`/candidates/${selectedIntID}`, {
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
				})
				.then((response) => response.data);
		},
		onSuccess: (data) => {
			setIsDeletingCandidate(false);
			console.log("delete candidate", data);
			queryClient.invalidateQueries({ queryKey: ["all-candidates"] });
			setDeleteStatus("success");
		},
		onError: (error) => {
			setIsDeletingCandidate(false);
			console.error(error);
			setDeleteStatus("error");
		},
	});

	const candidateDetailsRows = [
		{ title: "Experience", description: candidateDetails.workExperience },
		{ title: "Skills", description: candidateDetails.skills },
		{ title: "Education", description: candidateDetails.education },

		{
			title: "Source",
			description: candidateDetails.source ? candidateDetails.source : "N/A",
		},
		{
			title: "Status",
			description: candidateDetails.status ? candidateDetails.status : "N/A",
		},
		{
			title: "Salary Range",
			description:
				candidateDetails.expectedMinSalary &&
				candidateDetails.expectedMaxSalary ? (
					`$${candidateDetails.expectedMinSalary} - $${candidateDetails.expectedMaxSalary}`
				) : candidateDetails.expectedMinSalary ? (
					<div
						style={{
							display: "flex",
							gap: "5px",
							alignItems: "center",
						}}
					>
						<Tag>Expected Minimum Salary</Tag> HKD${" "}
						{candidateDetails.expectedMinSalary}
					</div>
				) : candidateDetails.expectedMaxSalary ? (
					<div
						style={{
							display: "flex",
							gap: "5px",
						}}
					>
						<Tag>Expected MAXimum Salary</Tag> HKD${" "}
						{candidateDetails.expectedMaxSalary}
					</div>
				) : (
					"Not Provided"
				),
		},
	];

	return (
		<Drawer
			modalType="non-modal"
			separator
			open={isDetailDrawerOpen}
			position="end"
			size="large"
		>
			<>
				<DrawerHeader>
					<DrawerHeaderTitle
						action={
							<Button
								appearance="subtle"
								aria-label="Close"
								icon={<Dismiss24Regular />}
								onClick={() => {
									setIsDetailDrawerOpen(false);
									setIsSearchPanelVisible(true);
								}}
							/>
						}
					>
						{" "}
						<div className="candidates-search candidates-detail-page-top-bar action-button">
							<Stack horizontal tokens={{ childrenGap: 10 }}>
								<Button
									className="candidates-detail-page-top-bar tool-bar-button view-report-button"
									icon={<DocumentDataRegular />}
									onClick={() =>
										window.open(
											`candidates/dashboard/${selectedIntID}`,
											"_blank"
										)
									}
									disabled={
										candidateAnalysis &&
										candidateAnalysis.currentOpenings.length === 0
											? true
											: false
									}
								>
									View Report
								</Button>

								<CvModal
									selectedIntID={selectedIntID}
									candidateAnalysis={candidateAnalysis}
								/>

								{showEditDetailsButton && (
									<Dialog modalType="non-modal" open={isFormOpen}>
										<DialogTrigger disableButtonEnhancement>
											<Button
												className="candidates-detail-page-top-bar tool-bar-button edit-candidate-button"
												icon={<Edit />}
												onClick={() => {
													setIsFormOpen(true);
												}}
											>
												Edit
											</Button>
										</DialogTrigger>
										<DialogSurface>
											<DialogBody>
												<DialogTitle
													action={
														<DialogTrigger>
															<Button
																appearance="subtle"
																aria-label="close"
																icon={<Dismiss24Regular />}
																onClick={() => setIsFormOpen(false)}
															/>
														</DialogTrigger>
													}
												>
													<EditFilled /> Personal Details of{" "}
													{candidateDetails.name}
												</DialogTitle>
												<DialogContent
													style={{
														display: "flex",
														flexDirection: "column",
														rowGap: "10px",
													}}
												>
													<Label htmlFor={"skills-input"}>Skills</Label>
													<Textarea
														required
														id={"skills-input"}
														value={editDetails?.skills}
														onChange={(e, newInput) =>
															handleInputChange("skills", newInput.value)
														}
														style={{ minHeight: "200px" }}
													/>
													<Label htmlFor={"source-input"}>Source</Label>
													<Combobox
														defaultValue={editDetails?.source}
														defaultSelectedOptions={[`${editDetails?.source}`]}
														onOptionSelect={(e, newInput) =>
															handleInputChange("source", newInput.optionValue)
														}
														placeholder="Select Source"
													>
														{sourceOptions.map((option) => (
															<Option text={option.text} key={option.key}>
																{option.text}
															</Option>
														))}
													</Combobox>

													<Label htmlFor={"status-input"}>Status</Label>
													<Combobox
														defaultValue={editDetails?.status}
														defaultSelectedOptions={[`${editDetails?.status}`]}
														onOptionSelect={(e, newInput) =>
															handleInputChange("status", newInput.optionValue)
														}
														placeholder="Select Status"
													>
														{statusOptions.map((option) => (
															<Option text={option.text} key={option.key}>
																{option.text}
															</Option>
														))}
													</Combobox>
													<Label>Salary Range</Label>
													<div
														className="candidates-search candidates-detail-page-content candidates-salary detail-page-items"
														style={{ maxWidth: "80%" }}
													>
														<div
															style={{
																display: "flex",
																flexDirection: "column",
																gap: "5px",
															}}
														>
															<div
																style={{
																	display: "flex",
																	gap: "5px",
																}}
															>
																<Tag>Expected Minimum Salary</Tag>
																<Input
																	type="number"
																	value={
																		editDetails?.expectedMinSalary?.toString() ??
																		""
																	}
																	onChange={(e, newInput) =>
																		handleInputChange(
																			"expectedMinSalary",
																			newInput.value
																		)
																	}
																	contentBefore={<Text size={400}>HKD $</Text>}
																	contentAfter={<Text>.00</Text>}
																/>
															</div>
															<div
																style={{
																	display: "flex",
																	gap: "5px",
																}}
															>
																<Tag>Expected Maximum Salary</Tag>
																<Input
																	type="number"
																	value={
																		editDetails?.expectedMaxSalary?.toString() ??
																		""
																	}
																	onChange={(e, newInput) =>
																		handleInputChange(
																			"expectedMaxSalary",
																			newInput.value
																		)
																	}
																	contentBefore={<Text size={400}>HKD $</Text>}
																	contentAfter={<Text>.00</Text>}
																/>
															</div>
														</div>
													</div>
												</DialogContent>
												<DialogActions>
													<DialogTrigger disableButtonEnhancement>
														<Button
															appearance="secondary"
															onClick={() => setIsFormOpen(false)}
														>
															Close
														</Button>
													</DialogTrigger>
													<Button
														type="submit"
														appearance="primary"
														onClick={(e) => handleSubmit(e, editDetails)}
													>
														Submit
													</Button>
												</DialogActions>
											</DialogBody>
											{showSpinner ? (
												<DialogSurface>
													<DialogBody>
														<DialogContent>
															<div style={{ height: "100px" }}>
																<Spinner
																	labelPosition="after"
																	label="Sending... "
																/>
															</div>
														</DialogContent>
													</DialogBody>
												</DialogSurface>
											) : null}

											{editErrorMsg ? (
												<DialogSurface>
													<DialogBody>
														<DialogContent>
															<div
																style={{
																	display: "flex",
																	flexDirection: "column",
																	gap: "10px",
																	alignItems: "center",
																}}
															>
																<Text>{editErrorMsg}</Text>
																<div>
																	<Button
																		onClick={() => setEditErrorMsg(undefined)}
																	>
																		Close
																	</Button>
																</div>
															</div>
														</DialogContent>
													</DialogBody>
												</DialogSurface>
											) : null}
										</DialogSurface>
									</Dialog>
								)}

								{showDeleteCandidateButton && (
									<Dialog modalType="alert">
										<DialogTrigger disableButtonEnhancement>
											<Button
												className="candidates-detail-page-top-bar tool-bar-button delete-candidate-button"
												icon={<DeleteRegular />}
												style={{
													backgroundColor: "#f5574b",
													color: "#ffffff",
												}}
											>
												Delete this candidate
											</Button>
										</DialogTrigger>

										<DialogSurface>
											<DialogBody>
												<DialogTitle>
													Are you sure to delete this candidate?
												</DialogTitle>
												<DialogContent>
													<div
														style={{
															display: "flex",
															justifyContent: "center",
														}}
													>
														<span
															style={{
																backgroundColor: "#FAFAFA",
																padding: "24px 48px",
																// fontWeight: "bold",
															}}
														>
															{candidateDetails?.name}
														</span>
													</div>
													<p>The delete action cannot be reverted.</p>
													<p>
														It will remove all the candidate's data from the
														system.
													</p>
												</DialogContent>

												<DialogActions>
													<DialogTrigger disableButtonEnhancement>
														<Button appearance="secondary">Close</Button>
													</DialogTrigger>
													<DialogTrigger>
														<Button
															style={{
																backgroundColor: "#f5574b",
																color: "#ffffff",
															}}
															onClick={() =>
																deleteCandidate.mutate(selectedIntID)
															}
														>
															Confirm
														</Button>
													</DialogTrigger>
												</DialogActions>
											</DialogBody>
										</DialogSurface>
									</Dialog>
								)}
							</Stack>
						</div>
					</DrawerHeaderTitle>
				</DrawerHeader>
				<DrawerBody className={styles.drawerBody}>
					{isLoading && (
						<div className={styles.loadingOverlay}>
							<Spinner label="Loading..." />
						</div>
					)}
					<div
						style={{
							width: "100%",
							height: "calc(100vh - 82px)",
							overflow: "auto",
							backgroundColor: "#f5f5f5",
						}}
					>
						{selectedIntID ? (
							candidateDetails.id ? (
								<div
									className="candidates-search candidates-detail-page-content candidates-info-container"
									style={{
										// display: "grid",
										// gridTemplateColumns: "2fr 1fr",
										padding: "12px 24px",
										gap: "12px",
										// height: "100%",
									}}
								>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											gap: "12px",
											height: "100%",
											overflow: "hidden",
											padding: "5px",
										}}
									>
										<div
											className="candidates-search candidates-detail-page-content candidates-personal-details"
											style={{
												display: "grid",
												backgroundColor: "#fff",
												borderRadius: "4px",
												boxShadow:
													"0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)",
											}}
										>
											<div
												style={{
													display: "flex",
													flexDirection: "column",
													padding: "24px",
												}}
											>
												<div
													style={{
														display: "flex",
														justifyContent: "space-between",
														alignItems: "center",
													}}
												>
													<div
														style={{
															display: "flex",
															alignItems: "center",
															height: "40px",
														}}
													>
														<Title1>{candidateDetails?.name}</Title1>
													</div>{" "}
												</div>
												{deleteStatus && (
													<Dialog open={deleteStatus ? true : false}>
														<DialogSurface>
															{deleteStatus === "success" ? (
																<DialogBody>
																	<DialogContent>
																		<p>
																			{" "}
																			The candidate has been deleted
																			successfully.
																		</p>
																		<p> Back to candidate search results.</p>
																	</DialogContent>
																	<DialogActions>
																		<Button
																			appearance="primary"
																			onClick={() => {
																				setDeleteStatus(undefined);
																				setIsDetailDrawerOpen(false);
																				setIsSearchPanelVisible(true);
																				setSelectedIntID(null);
																				refetch();
																			}}
																		>
																			Got it
																		</Button>
																	</DialogActions>
																</DialogBody>
															) : (
																<DialogBody>
																	<DialogContent>
																		<p> An unexpected error occured.</p>
																		<p>
																			{" "}
																			Please try again or contact IT support.
																		</p>
																	</DialogContent>
																	<DialogActions>
																		<Button appearance="primary">Got it</Button>
																	</DialogActions>
																</DialogBody>
															)}
														</DialogSurface>
													</Dialog>
												)}
												<div>
													<Text font="monospace">
														{candidateDetails?.currentProfile}
													</Text>
													<Text
														font="monospace"
														style={{
															display: "flex",

															alignItems: "center",
														}}
													>
														<ArrowCounterclockwise16Regular />
														Last Modified:{" "}
														{formattedDate(candidateDetails.lastModified)}
													</Text>
													<Text
														font="monospace"
														style={{
															display: "flex",

															alignItems: "center",
														}}
													>
														<Phone16Regular />
														Phone number:
													</Text>
													<Text
														font="monospace"
														style={{
															display: "flex",

															alignItems: "center",
														}}
													>
														<Mail16Regular />
														Email Address:
													</Text>
												</div>
											</div>
										</div>

										<div
											className="candidates-detail-container"
											style={{
												display: "flex",
												flexDirection: "column",
												flexGrow: 1,
												overflowY: "hidden",
												gap: 20,
												padding: "24px",
												backgroundColor: "#fff",
												borderRadius: "4px",
												boxShadow:
													"0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)",
											}}
										>
											<div
												className="candidates-detail-content"
												style={{ flexGrow: 1, overflowY: "auto" }}
											>
												{candidateDetailsRows.map((row, index) => (
													<div className="candidates-detail-row" key={index}>
														<div className="candidates-detail-title">
															<Subtitle1>{row.title}</Subtitle1>
														</div>
														<div className="candidates-detail-description">
															<div>
																{typeof row.description === "string" ? (
																	<p>{row.description}</p>
																) : Array.isArray(row.description) ? (
																	row.title === "Experience" ? (
																		(row.description as Experience[]).map(
																			(item, index) => (
																				<div key={index}>
																					<Text style={{ fontSize: "14px" }}>
																						{item.title} at {item.company},{" "}
																						{item.yearRange}
																					</Text>
																				</div>
																			)
																		)
																	) : row.title === "Education" ? (
																		(row.description as Education[]).map(
																			(item, index) => (
																				<div key={index}>
																					{item.degree ? (
																						<>
																							{item.degree} at {item.college},{" "}
																							{item.yearRange}
																						</>
																					) : (
																						<>
																							{item.college}, {item.yearRange}
																						</>
																					)}
																				</div>
																			)
																		)
																	) : (
																		<div style={{ fontStyle: "italic" }}>
																			No Record
																		</div>
																	)
																) : (
																	row.description
																)}
															</div>
														</div>
													</div>
												))}
											</div>
										</div>
									</div>
									<div style={{ gap: "12px", padding: "5px" }}>
										<Divider style={{ height: "100%" }} />

										<CandidatesComment
											key={`candidate-${selectedIntID}`}
											selectedIntID={selectedIntID}
											candidateAnalysis={candidateAnalysis}
										/>
									</div>
								</div>
							) : (
								<CandidateLoadingSkeleton />
							)
						) : (
							<></>
						)}
					</div>
				</DrawerBody>
			</>
		</Drawer>
	);
}
