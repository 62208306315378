import React, { useState, useEffect, RefObject, ChangeEvent } from "react";
import { useMsal } from "@azure/msal-react";
import { getRolePermissions } from "../../permissions/rolePermissions";
import {
	Text,
	TextField,
	Stack,
	Modal,
	IIconProps,
	IconButton,
	IButtonStyles,
	getTheme,
	Dropdown,
	IDropdownOption,
	PrimaryButton,
	DefaultButton,
	ITextField,
	Callout,
	FontWeights,
	TooltipHost,
} from "@fluentui/react";
import {
	Label,
	makeStyles,
	shorthands,
	mergeClasses,
	tokens,
	Tooltip,
	Field,
	Dialog,
	DialogActions,
	DialogTrigger,
	DialogSurface,
	DialogBody,
	DialogContent,
	Spinner,
	Button,
	Drawer,
	DrawerBody,
	DrawerHeader,
	DrawerHeaderTitle,
} from "@fluentui/react-components";
import {
	Info16Regular,
	AddRegular,
	DismissRegular,
	ArrowUploadRegular,
} from "@fluentui/react-icons";
import { setDocumentTitle } from "../../utils/documentTitle";

import { useBoolean, useId } from "@fluentui/react-hooks";
import EmployeeTable from "./JobTable";
import axios from "axios";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import Loading from "../../components/Loading";
import attributesSuggestions from "./candidateAttributes.json";
import "../../styles/JobOpenings.css";

const MoreIcon: IIconProps = { iconName: "More" };
export interface IReview {
	_id: number | undefined;
	recommendation: string | undefined;
	score: number | undefined;
	name: string | undefined;
	candidateId: number | undefined;
}
export interface ICriteria {
	name: string | undefined;
	score: number | undefined;
}
export interface IJob {
	_id: number | undefined;
	title: string | undefined;
	marketSalary: any;
	minInternalSalary: any;
	maxInternalSalary: any;
	postDate: string | undefined;
	status: string | undefined;
	requiredSkill: string | undefined;
	requirement: string | undefined;
	responsibility: string | undefined;
	review: IReview[];
	criteria: ICriteria[];
}
const options: IDropdownOption[] = [
	{ key: "Open", text: "Open" },
	{ key: "Closed", text: "Closed" },
	{ key: "Under Review", text: "Under Review" },
];

const useStyles = makeStyles({
	visible: {
		color: tokens.colorNeutralForeground2BrandSelected,
	},
	tag: {
		display: "flex",
		alignItems: "center",
		...shorthands.margin("2px 0"),
		...shorthands.borderRadius("5px"),
		backgroundColor: "#ECE5FD",
		whiteSpace: "nowrap",
		color: "#6074EF",
	},
	loadingTag: {
		display: "flex",
		alignItems: "center",
		...shorthands.margin("2px 0"),
		...shorthands.borderRadius("5px"),
		backgroundColor: "#EBEBEB",
		whiteSpace: "nowrap",
		color: "#333333",
	},
	wrongTag: {
		display: "flex",
		alignItems: "center",
		...shorthands.margin("2px 0"),
		...shorthands.borderRadius("5px"),
		backgroundColor: "#FF4D4D",
		whiteSpace: "nowrap",
		color: "#ffffff",
	},
	suggestion: {
		cursor: "pointer",
		display: "flex",
		alignItems: "center",
		...shorthands.margin("2px 0"),
		...shorthands.borderRadius("5px"),
		backgroundColor: "#f3f2f1",
		whiteSpace: "nowrap",
		color: "#333333",
		...shorthands.padding("5px 7px"),
		...shorthands.border("1px solid #333333"),
	},
	allSuggestions: {
		cursor: "pointer",
		display: "flex",
		alignItems: "center",
		...shorthands.margin("5px"),
		...shorthands.borderRadius("5px"),
		backgroundColor: "#f3f2f1",
		whiteSpace: "nowrap",
		color: "#333333",
		...shorthands.padding("5px 7px"),
		...shorthands.border("1px solid #333333"),
	},
	tagButton: {
		display: "flex",
		...shorthands.padding("0 4px"),
		...shorthands.border("none"),
		backgroundColor: "transparent",
		cursor: "pointer",
		color: "#666666",
	},
	title: {
		fontWeight: FontWeights.semilight,
	},
	callout: {
		width: "360px",
		...shorthands.padding("20px 24px"),
	},
});

const JobOpenings = () => {
	const { instance } = useMsal();
	let activeAccount;

	let userRoles: string[] = [];
	if (instance) {
		activeAccount = instance.getActiveAccount();
		if (activeAccount?.idTokenClaims?.roles) {
			userRoles = activeAccount.idTokenClaims.roles as string[];
		}
	}

	const { showUploadButton, showEditDetailsButton, showDeleteCandidateButton } =
		getRolePermissions(activeAccount);
	const styles = useStyles();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModalUpload, setIsModalUpload] = useState(false);
	const [isModalOpen1, setIsModalOpen1] = useState(false);
	const [isUploadDrawerOpen, setIsUploadDrawerOpen] = useState(false);
	const [isEdit, setIsEdit] = useState<any>(false);
	const [isDisabled, setIsDisabled] = useState<any>(true);
	const [isCriteriaInputValid, setIsCriteriaInputValid] = useState<string>("");
	const [isCriteriaFocused, setIsCriteriaFocused] = useState(false);
	const [openingIds, setOpeningIds] = useState<any>([]);
	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const [selectedMatchedRows, setSelectedMatchedRows] = useState<string[]>([]);
	const [uploadStatus, setUploadStatus] = useState<any>(false);
	const [isFormValid, setIsFormValid] = useState(false);
	const [editSuccess, setEditSuccess] = useState(false);
	const [isFormClicked, setIsFormClicked] = useState(false);
	const inputFieldsValidation: any = {
		title: /^.+$/,
		responsibility: /^.+$/,
		requirement: /^.+$/,
		requiredSkill: /^.+$/,
		criteria: /^[a-zA-Z\s()#@:-]+,[\s]*\d+$/,
		status: /^(Active|Inactive)$/,
	};
	const contentId = useId("content");
	const [visible, setVisible] = React.useState(false);
	const [existingCriteria, setExistingCriteria] = useState<any[]>([]);
	const [criteriaSuggestions, setCriteriaSuggestions] = useState<any[]>([]);
	const [allCriteriaSuggestions, setAllCriteriaSuggestions] = useState<any[]>(
		attributesSuggestions
	);
	const [isSuggestionClicked, setIsSuggestionClicked] = useState(false);
	const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
		useBoolean(false);
	const buttonId = useId("callout-button");
	const labelId = useId("callout-label");
	const descriptionId = useId("callout-description");
	const criteriaInputRef: RefObject<ITextField> = React.createRef();
	const [input, setInput] = useState<string>("");
	const [tags, setTags] = useState<string[]>([]);
	const [isKeyReleased, setIsKeyReleased] = useState(false);
	const [isKeyEnter, setIsKeyEnter] = useState(false);
	const [isTagDelete, setIsTagDelete] = useState(false);
	const [newTags, setNewTags] = useState<string[]>([]);

	const [failSpellCheckIndex, setFailSpellCheckIndex] = useState<number[]>([]);
	const [spellcheckLoading, setSpellcheckLoading] = useState(false);
	const defaultDate = new Date();
	const emptyFormData = {
		_id: undefined,
		title: "",
		marketSalary: "",
		minInternalSalary: "",
		maxInternalSalary: "",
		postDate: defaultDate.toISOString().split("T")[0],
		status: "",
		requiredSkill: "",
		requirement: "",
		responsibility: "",
		review: [],
		criteria: [],
	};
	const [formErrors, setFormErrors] = useState({
		title: { required: false },
		requiredSkill: { required: false },
		requirement: { required: false },
		responsibility: { required: false },
		status: { required: false },
		criteria: { required: false },
	});
	const [formData, setFormData] = useState<IJob>(emptyFormData);
	const queryClient = useQueryClient();
	const convertCriteriaInForm = (item: string[]) => {
		const criteriaArray = item.map((line) => {
			const [name, score] = line.split(",");
			return { name, score: parseInt(score) };
		});
		return criteriaArray;
	};

	useEffect(() => {
		setDocumentTitle("Job openings");
	}, []);

	const { isLoading, error, data } = useQuery(
		["all-openings"],
		() => axios.get(`/openings/`).then((response) => response.data),
		{ staleTime: 5 * 60 * 1000 } // 5 mins stale time
	);
	const addOpening = useMutation({
		mutationFn: (newData: IJob) => {
			setEditSuccess(false);
			return axios
				.post(`/openings/`, convertSalaryToNumber(newData), {
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
				})
				.then((response) => response.data);
		},
		onSuccess: (data: IJob) => {
			console.log(data);
			setEditSuccess(true);
			queryClient.invalidateQueries({ queryKey: ["all-openings"] });
			hideModal();
			setUploadStatus("success");
		},
		onError: (error) => {
			console.error(error);
			setUploadStatus("error");
		},
	});
	const editOpening = useMutation({
		mutationFn: (opening: IJob) => {
			setEditSuccess(false);
			return axios
				.put(`/openings/${opening._id}`, opening, {
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
				})
				.then((response) => response.data);
		},
		onSuccess: (data: IJob) => {
			console.log(data);
			setEditSuccess(true);
			queryClient.invalidateQueries({ queryKey: ["all-openings"] });
			hideModal();
			setUploadStatus("success");
		},
		onError: (error) => {
			console.error(error);
			setUploadStatus("error");
		},
	});
	const deleteOpening = useMutation({
		mutationFn: (id: any) => {
			return axios.delete(`/openings/${id}`, {
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
			});
		},
		onSuccess: (data, vars, ctx) => {
			console.log(`Opening id ${vars.id} deleted.`);
			queryClient.invalidateQueries({ queryKey: ["all-openings"] });
			hideModal();
		},
		onError: (error) => {
			console.error(error);
		},
	});
	const handleInputChange = (fieldName: any, value: any) => {
		setFormData({ ...formData, [fieldName]: value });
		setFormErrors({ ...formErrors, [fieldName]: false });
	};
	useEffect(() => {
		const arrCriteria: string[] = [];
		const mappedCriteria = formData.criteria.map(
			(criteria) =>
				`${criteria.name?.charAt(0).toUpperCase()}${criteria.name?.slice(1)}, ${
					criteria.score
				}`
		);
		arrCriteria.push(...mappedCriteria);
		setTags(arrCriteria);
		const arr: string[] = [];
		convertCriteriaInForm(arrCriteria).forEach((criteria) => {
			arr.push(criteria.name);
		});
		setExistingCriteria(arr);
	}, [formData]);
	const generatedCriteriaSuggestion = () => {
		const filteredSuggestions = attributesSuggestions.filter(
			(criteria) => !existingCriteria.includes(criteria.key)
		);
		const shuffled = filteredSuggestions.sort(() => 0.5 - Math.random());
		return shuffled;
	};
	const handleCriteriaSuggestionClick = (criteria: any, index: number) => {
		if (spellcheckLoading || failSpellCheckIndex.length !== 0) {
			return;
		} else {
			setIsSuggestionClicked(true);
			setIsCriteriaInputValid(
				inputFieldsValidation.criteria.test(criteria.key)
			);
			setInput(`${criteria.key}`);
			setCriteriaSuggestions((prev) =>
				prev.filter((item, i) => item.key !== criteria.key)
			);
			setAllCriteriaSuggestions((prev) =>
				prev.filter((item, i) => item.key !== criteria.key)
			);
			criteriaInputRef.current?.focus();
		}
	};
	useEffect(() => {
		setCriteriaSuggestions(generatedCriteriaSuggestion().slice(0, 4));
	}, [isModalOpen, isSuggestionClicked]);
	if (criteriaSuggestions.length === 0) {
		setCriteriaSuggestions(generatedCriteriaSuggestion().slice(0, 4));
	}
	useEffect(() => {
		if (!isModalOpen) {
			setFormErrors({
				title: {
					required: false,
				},
				requiredSkill: { required: false },
				requirement: { required: false },
				responsibility: { required: false },
				status: { required: false },
				criteria: { required: false },
			});
			setFailSpellCheckIndex([]);
			setIsFormClicked(false);
		}
	}, [isModalOpen]);
	const onCriteriaChange = (fieldName: string, e: any) => {
		const { value } = e.target;
		setIsFormClicked(false);
		setInput(value);
		setIsKeyEnter(false);
		setIsCriteriaInputValid(inputFieldsValidation.criteria.test(value));
		console.log("value", value);
	};
	const onKeyDown = (e: any) => {
		const { key } = e;
		const trimInput = input.trim();
		if (key === "Enter" && trimInput.length && !tags.includes(trimInput)) {
			e.preventDefault();
			if (!inputFieldsValidation.criteria.test(input)) {
				return;
			} else {
				const word = [trimInput.charAt(0).toUpperCase() + trimInput.slice(1)];
				const keyword = convertCriteriaInForm(word)[0].name;
				setSpellcheckLoading(true);
				setIsKeyEnter(true);
				setTags((prev) => [
					...prev,
					`${trimInput.charAt(0).toUpperCase()}${trimInput.slice(1)}`,
				]);
				setNewTags((prev) => [...prev, `${keyword}`]);
				console.log("onKeyEnter input", trimInput);
				setInput("");
			}
			console.log("tags", tags);
		}
		if (key === "Backspace" && !input.length && tags.length && isKeyReleased) {
			e.preventDefault();
			setIsKeyEnter(false);
			const tagsCopy = [...tags];
			const popTag: any = tagsCopy.pop();
			setTags(tagsCopy);
			setInput(popTag);
			console.log("popTag");
			console.log(popTag);
			console.log(tagsCopy);
		}
		setIsKeyReleased(false);
	};

	const onCriteriaFocus = () => {
		setIsCriteriaFocused(true);
	};

	const onCriteriaBlur = () => {
		setIsCriteriaFocused(false);
		setIsKeyEnter(false);
	};

	const criteriaSpellCheck = async () => {
		const arr: string[] = [];
		const indexes: number[] = [];
		console.log("check the time when the function is triggered");
		const criteriaArr = newTags;
		console.log("criteriaArr before spellcheck", criteriaArr);
		for (const criteria of criteriaArr) {
			console.log("check criteria", criteria);
			try {
				const response = await axios.post("/spellcheck", {
					text: `${criteria}`,
				});
				const data = response.data;
				if (data.content === "No") {
					console.log(
						"not ok",
						criteria,
						tags.findIndex((item) => item.includes(criteria))
					);
					const index = tags.findIndex((item) => item.includes(criteria));
					if (index !== -1) {
						indexes.push(index);
					} else {
						return;
					}
				} else {
					setNewTags((prev) =>
						[...prev].filter((item) => !item.includes(criteria))
					);
					console.log(
						"ok",
						criteria,
						tags.findIndex((item) => item.includes(criteria))
					);
					setSpellcheckLoading(false);
				}
				setSpellcheckLoading(false);
			} catch (error) {
				console.error(error);
				setSpellcheckLoading(false);
			}
		}
		console.log("indexes after running criteriaSpellCheck", indexes);
		setIsKeyEnter(false);
		return setFailSpellCheckIndex(indexes);
	};

	useEffect(() => {
		setFormData({ ...formData, ["criteria"]: convertCriteriaInForm(tags) });
		if (isKeyEnter || isTagDelete) {
			criteriaSpellCheck();
		}
	}, [isKeyEnter, isTagDelete]);

	const onKeyUp = (e: any) => {
		setIsKeyReleased(true);
	};

	const deleteTag = (index: number) => {
		const deletedCriteria = tags[index];
		const keyword = convertCriteriaInForm([deletedCriteria])[0].name;
		console.log("deletedCriteria", deletedCriteria);
		setTags((prev) => [...prev].filter((_, id) => id !== index));
		setFailSpellCheckIndex((prev) => [...prev].filter((_, id) => id !== index));
		setNewTags((prev) => [...prev].filter((item) => !item.includes(keyword)));
		setIsTagDelete(true);
		setTimeout(() => setIsTagDelete(false), 0);
	};

	function convertSalaryToNumber(formData: IJob) {
		formData.marketSalary =
			formData.marketSalary === "" ? null : formData.marketSalary;
		formData.minInternalSalary =
			formData.minInternalSalary === "" ? null : formData.minInternalSalary;
		formData.maxInternalSalary =
			formData.maxInternalSalary === "" ? null : formData.maxInternalSalary;
		return formData;
	}

	const saveForm = (e: any, data: any) => {
		setIsFormClicked(true);
		const newErrors = {
			title: { required: data.title.trim() === "" },
			requiredSkill: { required: data.requiredSkill.trim() === "" },
			requirement: { required: data.requirement.trim() === "" },
			responsibility: { required: data.responsibility.trim() === "" },
			status: { required: data.status.trim() === "" },
			criteria: { required: tags.length < 1 },
		};
		setFormErrors(newErrors);
		if (
			Object.values(newErrors).some((error) => error.required) ||
			failSpellCheckIndex.length > 0 ||
			spellcheckLoading ||
			tags.length < 2
		) {
			setIsFormValid(false);
			return;
		} else {
			setIsFormValid(true);
			setUploadStatus("loading");
			if (isEdit === 0 || Boolean(isEdit)) {
				console.log("isEdit", isEdit);
				console.log("formData editOpening", formData);
				editOpening.mutate(formData);
				setIsEdit(false);
			} else {
				console.log("formData addOpening", formData);
				addOpening.mutate(formData);
				console.log("Data:", data);
				setIsEdit(false);
				hideModal();
			}
		}
	};

	const showModal = () => {
		setIsModalOpen(true);
		console.log("show form data");
		console.log(formData);
	};

	const hideModal = () => {
		setIsModalOpen(false);
		setFormData(emptyFormData);
		setNewTags([]);
		setIsEdit(false);
		setInput("");
	};

	const showModal1 = () => {
		setIsModalOpen1(true);
	};

	const hideModal1 = () => {
		setIsModalOpen1(false);
	};

	const handleEdit = (id: any) => {
		setIsEdit(id);
		const filtered = data.find((v: IJob, i: number) => v._id === id);
		setFormData({ ...filtered });
		showModal();
	};

	const onDelete = () => {
		openingIds.forEach((id: any) => {
			deleteOpening.mutate(id);
			setSelectedRows(selectedRows.filter((id: string) => id !== id));
			setOpeningIds(selectedRows.filter((id: string) => id !== id));
		});
		setFormData(emptyFormData);
		setIsDisabled(true);
		hideModal1();
	};

	const [uploadErrorValidationMsg, setUploadErrorValidationMsg] =
		useState<any>(null);
	const [uploadSuccess, setUploadSuccess] = useState(false);
	const [isUploadInProgress, setIsUploadInProgress] = useState<any>(false);
	const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
	const resetForm = () => {
		setSelectedFiles([]);
		setIsUploadInProgress(false);
		setUploadErrorValidationMsg(null);
	};

	const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		if (files) {
			const filesArray = Array.from(files);
			setSelectedFiles(filesArray);
		}
	};

	const handleUploadClick = async () => {
		const formData = new FormData();
		selectedFiles.forEach((file) => {
			formData.append("files", file);
		});
		console.log("selected files", selectedFiles);
		try {
			setIsUploadInProgress(true);
			const response = await axios.post(`/openings/jd`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});
			console.log(response);
			if (response.status === 200 || response.status === 202) {
				setUploadSuccess(true);
				setIsUploadInProgress(false);
				resetForm();
				setUploadErrorValidationMsg(null);
			} else {
				const error = response.data.message || `HTTP Error ${response.status}`;
				console.error(error);
				setIsUploadInProgress(false);
				resetForm();
				setUploadErrorValidationMsg(error);
				return Promise.reject(error);
			}
		} catch (error) {
			console.error(error);
			setIsUploadInProgress(false);
		}
	};

	return (
		<div style={{ position: "relative" }}>
			<div style={{ padding: "24px" }}>
				<Stack
					horizontal
					style={{
						justifyContent: "space-between",
					}}
				>
					{showUploadButton && (
						<Stack
							horizontal
							tokens={{ childrenGap: 10 }}
							className="job-stack"
							style={{
								alignItems: "center",
							}}
						>
							<Button
								onClick={() => setIsUploadDrawerOpen(!isUploadDrawerOpen)}
								icon={<ArrowUploadRegular />}
								appearance="primary"
							>
								Upload Job Opening(s)
							</Button>
							<Button
								icon={<AddRegular />}
								appearance="outline"
								onClick={() => {
									setFormData(emptyFormData);
									showModal();
								}}
							>
								Post A New Job
							</Button>
							<Button
								icon={<DismissRegular />}
								onClick={showModal1}
								disabled={isDisabled}
							>
								Delete
							</Button>
							<Drawer
								type="overlay"
								separator
								open={isUploadDrawerOpen}
								position="end"
								size="medium"
							>
								<DrawerHeader>
									<DrawerHeaderTitle
										action={
											<Button
												appearance="subtle"
												aria-label="Close"
												icon={<DismissRegular />}
												onClick={() => setIsUploadDrawerOpen(false)}
											/>
										}
									>
										Upload Job Opening(s)
									</DrawerHeaderTitle>
								</DrawerHeader>
								<DrawerBody>
									<div className="upload-container">
										<div>
											<Field
												label=""
												validationMessage={uploadErrorValidationMsg}
											>
												<div style={{ height: "20vh" }}>
													<input
														multiple
														id="fileInput"
														type="file"
														name="files"
														onChange={handleFileChange}
														accept=".pdf,.docx,.doc"
													/>
													<ul>
														{selectedFiles.map((file, i) => (
															<li key={i}>{file.name}</li>
														))}
													</ul>
												</div>
												<Button
													appearance="primary"
													onClick={handleUploadClick}
												>
													Submit
												</Button>
												<br />
												<div>
													{selectedFiles &&
														`Document Name: ${selectedFiles.map(
															(item) => item.name
														)}`}
												</div>
											</Field>
											<br />
											{!isUploadInProgress ? null : (
												<Spinner
													appearance="primary"
													labelPosition="after"
													label="Uploading new document..."
												/>
											)}
											{uploadSuccess && (
												<Dialog open={uploadSuccess}>
													<DialogSurface>
														<DialogBody>
															<DialogContent>
																A new job opening is created successfully.
																Please refresh to see the changes.
															</DialogContent>
															<DialogActions>
																<Button
																	appearance="primary"
																	onClick={() => {
																		setUploadSuccess(false);
																		setIsUploadDrawerOpen(false);
																	}}
																>
																	Got it
																</Button>
															</DialogActions>
														</DialogBody>
													</DialogSurface>
												</Dialog>
											)}
										</div>
									</div>
								</DrawerBody>
							</Drawer>
						</Stack>
					)}
				</Stack>
			</div>
			<Modal
				isOpen={isModalOpen}
				onDismiss={hideModal}
				isBlocking={false}
				containerClassName="modal-container"
				scrollableContentClassName="modal-scroll-content"
			>
				<IconButton
					styles={iconButtonStyles}
					iconProps={cancelIcon}
					ariaLabel="Close popup modal"
					onClick={hideModal}
				/>
				<Stack
					className="pop-up-window"
					tokens={{ childrenGap: 10 }}
					styles={{ root: { width: 720, padding: 16 } }}
				>
					<Stack
						horizontal
						tokens={{ childrenGap: 10 }}
						style={{ width: "100%" }}
						className="job-from"
					>
						<TextField
							label="Job Title"
							required={true}
							value={formData.title}
							onChange={(e, newValue) => handleInputChange("title", newValue)}
							errorMessage={
								formErrors.title.required ? "Title is required" : undefined
							}
						/>
						<TextField
							prefix="HKD $"
							label="Market Salary"
							value={formData.marketSalary}
							onChange={(e, newValue) =>
								handleInputChange("marketSalary", newValue)
							}
							type="number"
						/>
					</Stack>
					<Stack
						horizontal
						tokens={{ childrenGap: 10 }}
						style={{ width: "100%" }}
						className="job-from"
					>
						<TextField
							prefix="HKD $"
							label="Min internal salary"
							value={formData.minInternalSalary}
							onChange={(e, newValue) =>
								handleInputChange("minInternalSalary", newValue)
							}
							type="number"
						/>
						<TextField
							prefix="HKD $"
							label="Max internal salary"
							value={formData.maxInternalSalary}
							onChange={(e, newValue) =>
								handleInputChange("maxInternalSalary", newValue)
							}
							type="number"
						/>
						<TextField
							label="Post Date"
							type="date"
							required={true}
							value={
								formData.postDate
									? formData.postDate
									: defaultDate.toISOString().split("T")[0]
							}
							onChange={(e, newValue) =>
								handleInputChange("postDate", newValue)
							}
						/>
					</Stack>
					<Stack
						horizontal
						tokens={{ childrenGap: 10 }}
						style={{ width: "100%" }}
						className="job-from"
					>
						<TextField
							multiline
							autoAdjustHeight
							label="Responsibilities"
							value={formData.responsibility}
							onChange={(e, newValue) =>
								handleInputChange("responsibility", newValue)
							}
							styles={{ field: { maxHeight: 100, overflow: "auto" } }}
							required={true}
							errorMessage={
								formErrors.responsibility.required
									? "Responsibility is required"
									: undefined
							}
						/>
					</Stack>
					<Stack
						horizontal
						tokens={{ childrenGap: 10 }}
						style={{ width: "100%" }}
						className="job-from"
					>
						<TextField
							multiline
							autoAdjustHeight
							label="Requirement"
							value={formData.requirement}
							onChange={(e, newValue) =>
								handleInputChange("requirement", newValue)
							}
							styles={{ field: { maxHeight: 100, overflow: "auto" } }}
							required={true}
							errorMessage={
								formErrors.requirement.required
									? "Requirement is required"
									: undefined
							}
						/>
					</Stack>
					<TextField
						multiline
						autoAdjustHeight
						label="Required Skills"
						value={formData.requiredSkill}
						onChange={(e, newValue) => {
							handleInputChange("requiredSkill", newValue);
						}}
						styles={{ field: { maxHeight: 100, overflow: "auto" } }}
						required={true}
						errorMessage={
							formErrors.requiredSkill.required
								? "Required Skill is required"
								: undefined
						}
					/>
					<div>
						<div
							aria-owns={visible ? contentId : undefined}
							style={{
								display: "flex",
								columnGap: "6px",
								fontWeight: 600,
								padding: "5px 0",
							}}
						>
							<Label>Criteria for Analysis</Label>
							<Tooltip
								content={{
									children:
										"Please input criteria and requirement scores in the following format: \n Interpersonal, 7",
									id: contentId,
								}}
								positioning="above-start"
								withArrow
								relationship="label"
								onVisibleChange={(data: any) => setVisible(data.visible)}
							>
								<Info16Regular
									tabIndex={0}
									className={mergeClasses(visible && styles.visible)}
								/>
							</Tooltip>
							<span style={{ color: "#a4262c" }}>*</span>
						</div>
						<div>
							Enter the criteria or select the suggested criteria then enter the
							score
						</div>
						<div style={{ display: "flex" }}>
							<div
								style={{
									display: "flex",
									flexGrow: 1,
									columnGap: "6px",
									padding: "6px 0",
								}}
							>
								{criteriaSuggestions.map((suggestion, index) => (
									<div
										className={styles.suggestion}
										onClick={() =>
											handleCriteriaSuggestionClick(suggestion, index)
										}
									>
										<DefaultButton>{suggestion.text}</DefaultButton>
									</div>
								))}
								{isCalloutVisible && (
									<Callout
										className={styles.callout}
										ariaLabelledBy={labelId}
										ariaDescribedBy={descriptionId}
										role="dialog"
										gapSpace={0}
										target={`#${buttonId}`}
										onDismiss={toggleIsCalloutVisible}
										setInitialFocus
									>
										<div
											style={{
												padding: "20px 24px",
											}}
										>
											<div
												style={{
													display: "flex",
													flexWrap: "wrap",
													height: "200px",
													border: "1px solid #666666",
													overflowY: "scroll",
													justifyContent: "center",
													backgroundColor: "#f4f4f4",
												}}
											>
												{allCriteriaSuggestions.map((suggestion, index) => (
													<div
														className={styles.allSuggestions}
														onClick={() =>
															handleCriteriaSuggestionClick(suggestion, index)
														}
													>
														<DefaultButton>{suggestion.text}</DefaultButton>
													</div>
												))}
											</div>
										</div>
									</Callout>
								)}
							</div>
							<TooltipHost content="See more criteria">
								<IconButton
									iconProps={MoreIcon}
									aria-label="See more"
									id={buttonId}
									onClick={toggleIsCalloutVisible}
								>
									See more
								</IconButton>
							</TooltipHost>
						</div>
						<div
							className="criteria-wrapper"
							style={{
								display: "flex",
								padding: "6px 8px",
								boxSizing: "border-box",
								outline:
									isCriteriaFocused && isFormClicked && tags.length < 1
										? "2px solid #a4262c"
										: isCriteriaFocused
										? "2px solid #6666CC"
										: isFormClicked && tags.length < 1
										? "1px solid #a4262c"
										: "1px solid #666666",
							}}
						>
							<div
								style={{
									display: "flex",
									gap: "4px",
									flexWrap: "wrap",
									width: "100%",
								}}
							>
								{tags.map((tag, index) => (
									<div
										key={index}
										className={
											failSpellCheckIndex[0] === index &&
											tags.some((tag) => tag.includes(newTags[0]))
												? styles.wrongTag
												: styles.tag
										}
									>
										<span style={{ margin: "0 8px" }}>{tag}</span>
										<IconButton
											className={styles.tagButton}
											iconProps={cancelIcon}
											onClick={() => deleteTag(index)}
										/>
									</div>
								))}
								{spellcheckLoading ? (
									<div style={{ display: "flex", paddingLeft: "8px" }}>
										<Loading />
										<p
											style={{
												margin: 0,
												alignSelf: "center",
												padding: "8px",
												color: "#858585",
											}}
										>
											Please wait until spellchecking is done
										</p>
									</div>
								) : !spellcheckLoading && failSpellCheckIndex.length < 1 ? (
									<TextField
										placeholder="Add criteria and score"
										componentRef={criteriaInputRef}
										borderless={true}
										autoAdjustHeight
										value={input}
										onKeyDown={onKeyDown}
										onFocus={onCriteriaFocus}
										onBlur={onCriteriaBlur}
										onKeyUp={(e) => {
											onKeyUp(e);
										}}
										onChange={(e) => {
											onCriteriaChange("criteria", e);
										}}
										styles={{
											root: { display: "flex", flexGrow: 1 },
											wrapper: { display: "flex", flexGrow: 1 },
											fieldGroup: { display: "flex", flexGrow: 1 },
											field: { paddingLeft: 8 },
										}}
									/>
								) : null}
							</div>
						</div>
						{!isCriteriaInputValid && isCriteriaFocused && input ? (
							<div style={{ color: "#a4262c", fontSize: "12px" }}>
								Use a comma "," to separate the criteria and the score e.g.
								Interpersonal, 7
							</div>
						) : (
							isCriteriaFocused &&
							input && (
								<div style={{ fontSize: "12px" }}>
									Press "Enter" to input another criteria-score pair
								</div>
							)
						)}
					</div>
					{failSpellCheckIndex.length > 0 ? (
						<div
							style={{
								color: "#a4262c",
								fontSize: "12px",
								margin: 0,
								paddingTop: "5px",
							}}
						>
							Please check the spelling of the entered criteria
						</div>
					) : isFormClicked && tags.length < 2 ? (
						<div
							style={{
								color: "#a4262c",
								fontSize: "12px",
								margin: 0,
								paddingTop: "5px",
							}}
						>
							Criteria is required, please input at least 2 criteria in the
							required format.
						</div>
					) : null}
					<Dropdown
						placeholder="Status"
						label="Status"
						options={options}
						required={true}
						selectedKey={formData.status}
						onChange={(e, option: any) =>
							handleInputChange("status", option.key)
						}
						errorMessage={
							formErrors.status.required ? "Status is required" : undefined
						}
					/>
					<div style={{ color: "#a4262c", fontWeight: "bold" }}>
						<span style={{ fontSize: "14px" }}>*</span>
						<span style={{ fontSize: "12px" }}> Required Field</span>
					</div>
					<div>
						<Dialog modalType="modal" open={isFormValid && !editSuccess}>
							<DialogTrigger disableButtonEnhancement>
								{spellcheckLoading && isCriteriaInputValid ? (
									<PrimaryButton styles={buttonStyles} disabled>
										<Loading />
										Please wait
									</PrimaryButton>
								) : (
									<PrimaryButton
										styles={buttonStyles}
										onClick={(e) => saveForm(e, formData)}
									>
										Save
									</PrimaryButton>
								)}
							</DialogTrigger>
							<DialogSurface>
								<DialogBody>
									<DialogContent>
										{uploadStatus === "loading" ? (
											<div style={{ height: "100px" }}>
												<Spinner labelPosition="after" label="Sending... " />
											</div>
										) : uploadStatus === "success" ? (
											<div
												style={{
													height: "100px",
													display: "flex",
													justifyContent: "center",
												}}
											>
												Job Opening is Updated
											</div>
										) : (
											<div
												style={{
													height: "100px",
													display: "flex",
													justifyContent: "center",
												}}
											>
												Failed to Update Job Opening. Please Try Again or
												Contact IT Support.
											</div>
										)}
										<DialogActions>
											<Button
												appearance="primary"
												onClick={() => setEditSuccess(true)}
											>
												Close
											</Button>
										</DialogActions>
									</DialogContent>
								</DialogBody>
							</DialogSurface>
						</Dialog>
					</div>
				</Stack>
			</Modal>
			<Modal
				isOpen={isModalOpen1}
				onDismiss={hideModal1}
				isBlocking={false}
				containerClassName="modal-container"
				scrollableContentClassName="modal-scroll-content"
			>
				<Stack
					tokens={{ childrenGap: 10 }}
					style={{
						width: 600,
						padding: 16,
						textAlign: "center",
						alignItems: "center",
						marginTop: 8,
					}}
				>
					<Text variant="xLarge">Confirm to delete Job Openings</Text>
					<Text variant="mediumPlus">
						The delete action cannot be reverted. Are you confirm to delete the
						Job Openings?
					</Text>
					<Stack
						horizontal
						tokens={{ childrenGap: 10 }}
						style={{
							justifyContent: "center",
							marginTop: 14,
						}}
					>
						<PrimaryButton onClick={hideModal1}>Cancel</PrimaryButton>
						<PrimaryButton
							onClick={onDelete}
							style={{ backgroundColor: "red", border: "none" }}
						>
							Delete
						</PrimaryButton>
					</Stack>
				</Stack>
			</Modal>
			<EmployeeTable
				tableData={isLoading ? [] : data}
				onEdit={handleEdit}
				setOpeningIds={setOpeningIds}
				selectedRows={selectedRows}
				setSelectedRows={setSelectedRows}
				selectedMatchedRows={selectedMatchedRows}
				setSelectedMatchedRows={setSelectedMatchedRows}
				setIsDisabled={setIsDisabled}
			/>
		</div>
	);
};

export default JobOpenings;

const cancelIcon: IIconProps = { iconName: "Cancel" };
const theme = getTheme();
const iconButtonStyles: Partial<IButtonStyles> = {
	root: {
		color: theme.palette.neutralPrimary,
		marginLeft: "auto",
		marginTop: "4px",
		marginRight: "2px",
		display: "flex",
	},
	rootHovered: {
		color: theme.palette.neutralDark,
	},
};
const buttonStyles: Partial<IButtonStyles> = {
	root: {
		marginLeft: "auto",
		marginTop: "4px",
		marginRight: "2px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
};
