export type UserRole = "Admin" | "Interviewer";

export type Action = "upload" | "editDetails" | "deleteCandidate";

export const rolePermissions: { [key in UserRole]: Action[] } = {
	Admin: ["upload", "editDetails", "deleteCandidate"],
	Interviewer: [],
};

export const isAdmin = (roles: UserRole[]): boolean => roles.includes("Admin");

export const checkRoles = (userRoles: UserRole[], rolesToCheck: UserRole[] = []) => {
  const hasRole = (allowedRoles: UserRole[]) => allowedRoles.some((role) => userRoles.includes(role));

  return rolesToCheck.reduce((acc, role) => {
    acc[role] = hasRole([role]);
    return acc;
  }, {} as Record<string, boolean>);
};

export const getRolePermissions = (activeAccount: any) => {
  const useMockRoles = false; // Set this to true to use mock roles
  const mockRole: UserRole[] = ["Admin"];

  let userRoles: UserRole[] = [];

  if (useMockRoles) {
    userRoles = mockRole; 
  } else {
    if (activeAccount) {
      if (activeAccount.idTokenClaims?.roles) {
        userRoles = activeAccount.idTokenClaims.roles as UserRole[];
      }
    }
  }

  const roleCheckResults = checkRoles(userRoles, ["Admin", "Interviewer"]);

	return {
		showUploadButton: roleCheckResults["Admin"],
		showEditDetailsButton: roleCheckResults["Admin"],
		showDeleteCandidateButton: roleCheckResults["Admin"],
	};
};
